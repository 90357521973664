<template>
 <!--header component -->
  <div class="header text-center w-100 mt-4">
    <h1>پرسشنامه شغلی</h1>
  </div>
</template>

<script>
export default {
  name: 'Header',
}
</script>
